import React, { createContext, useEffect } from 'react';

export const RedirectContext = createContext();

export const RedirectContextProvider = ({ children }) => {
  useEffect(() => {
    const allowedHosts = ["central.gmitexas.com", "beta.central.gmitexas.com"];
    const currentHostname = window.location.hostname;

    if (allowedHosts.includes(currentHostname)) {
      const root = document.getElementById('root');
      if (root && !root.querySelector('.blocked')) {
        const div = document.createElement('div');
        div.className = 'blocked fixed inset-0 z-50 bg-white opacity-75 flex justify-center items-center';
        div.innerHTML = '<div>Redirecting ...</div>';
        root.appendChild(div);
      }
      let destinationURL = new URL(window.location.href);
      destinationURL.hostname = "central-v2.gmitexas.com";
      window.location.href = destinationURL.href;
    }
  }, []);

  return (
    <RedirectContext.Provider value={{}}>
      {children}
    </RedirectContext.Provider>
  );
};

