import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserIcon, LockClosedIcon } from "@heroicons/react/solid";
import { EyeOffIcon, EyeIcon } from "@heroicons/react/outline";

import { AuthContext } from "./shared/contexts/AuthContext";
import GMI_LOGO from "assets/gmi_logo.png";

const CustomInputWrapper = ({ children }) => (
  <div className="relative flex items-center w-full p-1 px-2 py-0 leading-6 border border-gray-400 rounded focus-within:ring-blue-600 focus-within:ring-1 focus-within:ring-offset-0 focus-within:border-blue-600 focus-within:ring-offset-white">
    {children}
  </div>
);

const inputClassNames =
  "w-full leading-6 bg-transparent border-none focus:border-none focus:ring-0 focus:ring-offset-0 text-black placeholder-gray-400";

const Login = () => {
  const { loginUser } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const location = useLocation();
  const afterLoginRedirectTo = location.state?.referrer || "/";

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser({ email, password }, afterLoginRedirectTo);
  };

  return (
    <main className="p-10 text-center bg-white">
      <div className="flex items-center justify-center pt-14">
        <img src={GMI_LOGO} alt="GMI Logo" className="h-20" />
      </div>
      <div className="pt-20">
        <div className="w-full px-4 py-5 m-auto text-gray-500 border border-gray-300 rounded shadow-sm max-w-400px">
          <form className="flex flex-col items-center justify-center w-full">
            <h2 className="text-2xl font-bold">Login</h2>
            <div className="w-full mt-3">
              <CustomInputWrapper>
                <UserIcon className="w-5 h-5 text-gray-400" />
                <input
                  className={inputClassNames}
                  type="text"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </CustomInputWrapper>
            </div>
            <div className="w-full mt-4 ">
              <CustomInputWrapper>
                <LockClosedIcon className="w-5 h-5 text-gray-400" />
                <input
                  className={inputClassNames}
                  type={passwordType}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  className="absolute top-1/2 right-3 -translate-y-1/2"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <EyeIcon className="w-6 h-6" />
                  ) : (
                    <EyeOffIcon className="w-6 h-6" />
                  )}
                </button>
              </CustomInputWrapper>
            </div>
            <input
              type="submit"
              className="w-full p-2 mt-4 text-lg font-bold text-white rounded cursor-pointer bg-teal hover:bg-tealDark disabled:hover:bg-teal disabled:cursor-not-allowed"
              onClick={handleSubmit}
              disabled={!password || !email}
              value={"Login"}
            />
          </form>
          <div className="mt-4 text-blue-600 cursor-pointer hover:text-blue-700">
            <Link to="/reset-password">Forgot your password?</Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
