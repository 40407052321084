import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { MailIcon, CheckCircleIcon } from '@heroicons/react/solid';

import { AuthContext } from './shared/contexts/AuthContext'

const CustomInputWrapper = ({ children }) => (
  <div className="relative flex items-center w-full p-1 px-2 py-0 leading-6 border border-gray-400 rounded focus-within:ring-blue-600 focus-within:ring-1 focus-within:ring-offset-0 focus-within:border-blue-600 focus-within:ring-offset-white">
    {children}
  </div>
);

const inputClassNames = "w-full leading-6 bg-transparent border-none focus:border-none focus:ring-0 focus:ring-offset-0 text-black placeholder-gray-400";

const ResetPasswordForm = () => {
  const { requestResetPassword } = useContext(AuthContext);
  
  const [email, setEmail] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const onSuccess = () => setShowSuccessMessage(true); //TODO: error, loading state etc
    
    requestResetPassword({ email }, onSuccess);
  }

  if (showSuccessMessage) return (
    <main className="p-10 text-center bg-white">
      <div className="flex items-center justify-center text-lg">
        <CheckCircleIcon className="w-12 h-12 mr-2 text-green-500" />
        <span>Thank you. Reset password email was sent to your provided email.</span>
      </div>
    </main>
  );

  return (
    <main className="p-10 text-center bg-white">
      <div className="w-full px-4 py-5 m-auto text-gray-500 border border-gray-300 rounded shadow-sm max-w-400px">
        <form className="flex flex-col items-center justify-center w-full">
          <h2 className="text-2xl font-bold">Reset Password</h2>
          <div className="w-full mt-3">
            <CustomInputWrapper>
              <MailIcon className="w-5 h-5 text-gray-400" />
              <input
                className={inputClassNames}
                type="email"
                placeholder="abc@mail.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </CustomInputWrapper>
          </div>
          <input 
            type="submit" 
            className="w-full p-2 mt-4 text-lg font-bold text-white rounded cursor-pointer bg-teal hover:bg-tealDark disabled:hover:bg-teal disabled:cursor-not-allowed" 
            onClick={handleSubmit}
            disabled={!email}
            value={"Send Email"}
          />
        </form>
        <div className="flex justify-center mt-4">
          <span className="mr-2">Already have an account?</span>
          <Link className="text-blue-600 cursor-pointer hover:text-blue-700" to="/">Login here</Link>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordForm;
