import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useClient from '../../util/useClient';
import { post } from '../../util/axios.js';
import { FeedbackContext } from './FeedbackContext';


export const AuthContext = React.createContext({
  user: null,
  isLoggedIn: false,
  authLoading: true,
  bouncerClient: null,
  loginUser: () => {},
  logoutUser: () => {},
  registerUser: () => {},
  resetPassword: () => {},
  requestResetPassword: () => {},
  updateAccountInformation: () => {},
});


export const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  const { showErrorToast } = useContext(FeedbackContext);
  const { showSuccessToast } = useContext(FeedbackContext); 

  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState(null);

  const bouncerClient = useClient(user);
	const getCurrentUser = bouncerClient.get("/api/auth/session");

  const fetchCurrentUser = () => {
    setAuthLoading(true);
    const onSuccess = _user => {
      if (!!_user) setUser(_user);
      setAuthLoading(false);
    }
    const onError = e => {
      setAuthLoading(false);
      showErrorToast(e);
    };
    if (getCurrentUser.canAccess()) return getCurrentUser.sendRequest({}, onSuccess, onError);
  }

  useEffect(() => {
    fetchCurrentUser();
  }, []);


  const loginUser = ({ email, password }, redirectTo) => {
	  const tryLogin = bouncerClient.post("/api/login");
	  if (!tryLogin.canAccess()) return showErrorToast("user is already logged in");
    
    
    const onSuccess = _user => {
      setUser(_user);
      history.push(`${redirectTo}`)
    };
    const onError = err => showErrorToast(err);
    
    const form = tryLogin.validateForm({ email, password });
    if (!form.errors) tryLogin.sendRequest(form, onSuccess, onError);
  }

  const logoutUser = () => {
	  const tryLogout = bouncerClient.get("/api/logout");
	  if (!tryLogout.canAccess()) return showErrorToast("user is not logged in");
    
    const onSuccess = () => setUser(null);
    const onError = err => showErrorToast(err);

    tryLogout.sendRequest({}, onSuccess, onError);
  }

  const registerUser = (userData, successCallback, errorCallback) => {
    const onSuccess = _user => {
      successCallback();
      setUser(_user);
    };
    
    const onError = err => {
      showErrorToast(err);
      errorCallback();
    };
    
    post('/api/invite-user-link', { ...userData }, onSuccess, onError);
  }

  const requestResetPassword = ({ email }, successCallback) => {
    const sendResetPasswordRequest = bouncerClient.post("/api/request-reset-password");
	  if (!sendResetPasswordRequest.canAccess()) return showErrorToast("invalid request");
    
    const onSuccess = () => successCallback();
    const onError = err => showErrorToast(err);
    
    sendResetPasswordRequest.sendRequest({ email }, onSuccess, onError);
  }

  const resetPassword = ({ resetPasswordToken, password }, successCallback, errorCallback) => {
    const tryResetPassword = bouncerClient.post("/api/reset-password-link");
	  if (!tryResetPassword.canAccess()) return showErrorToast("invalid request");
    
    const onSuccess = () => {
      successCallback();
    };
    
    const onError = err => {
      showErrorToast(err);
      errorCallback();
    };
    const form = tryResetPassword.validateForm({ resetPasswordToken, password });
    if (!form.errors) tryResetPassword.sendRequest(form, onSuccess, onError);
  }

  const updateAccountInformation = (accountData) => {

    const onSuccess = (res) => {
      showSuccessToast(res); 
    };
    const onError = err => {
      showErrorToast(err);
    };
    
    post('/api/account', { ...accountData }, onSuccess, onError);
  }

  const isLoggedIn = !!user;

  const contextVal = {
    user,
    isLoggedIn,
    authLoading,
    bouncerClient,
    loginUser,
    logoutUser,
    registerUser,
    resetPassword,
    requestResetPassword,
    updateAccountInformation,
  };

  return (
    <AuthContext.Provider value={contextVal}>
      {children}
    </AuthContext.Provider>
  );
}
